export default ($) => ({
  scrollHeader(){
    const act = function () {

      if($('.herobanner-full').length){
        let init = $('.splash-white').offset().top
        //let init = $('.splash-white').offset().top + $('.splash-white').outerHeight()
        // let black = $('.splash-black').offset().top + $('header').outerHeight()

        if ($(window).scrollTop() > init) {
          $('header').addClass('white');
          // setTimeout(function(){
          //   $('header').removeClass('hide');
          //   $('header').addClass('white');
          // }, 1000);

          // if ($(window).scrollTop() > black) {
          //   $('header').addClass('black');
          // } else {
          //   $('header').removeClass('black');
          // }
        } else {
          $('header').removeClass('white');
          //$('header').removeClass('hide');
        }
      } else {
        // $('header').addClass('black');
      }

      if($('.banner-content').length){
        let bannerContent = $('.banner-content').height() - 200;
        if ($(window).scrollTop() > bannerContent) {
          $('header').addClass('white');
        } else {
          $('header').removeClass('white');
        }
      }

    }
    if ($('body').find('.section-content.pt-large').length !== 0) {
      $('header').addClass('white');
    }

    act();
    $(window).scroll(function () {
      act();
    });

    if(window.innerWidth < 1200) {
      $('.wa-header__nav > li.have-sub-thumb > span i').click(function(e){
        e.preventDefault();
        if ( $(this).parents('.have-sub-thumb').hasClass('active') ) {
          $(this).parents('.have-sub-thumb').removeClass('active').find('.drop-sub-menu').hide();
        }else{
          $('.wa-header__nav > li.have-sub-thumb > span i').not($(this)).parents('.have-sub-thumb').removeClass('active').find('.drop-sub-menu').hide();
          $(this).parents('.have-sub-thumb').addClass('active').find('.drop-sub-menu').show();
        }
      })
      $(document).mouseup(function (e) { 
        if ($(e.target).closest(".wa-header__nav > li.have-sub-thumb > span i").length === 0) { 
          $('.wa-header__nav > li.have-sub-thumb').removeClass('active').find('.drop-sub-menu').hide();
        } 
      })
    }
  },
  varHeight(){
      const setHeight = function(){
          let vh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty('--vh', `${vh}px`);
      }
      setHeight();
      $(window).on('load',function () {
          setHeight();
      });
      $(window).on('resize',function () {
          setHeight();
      });
  },
  init() {
    this.varHeight()
    this.scrollHeader()
  }
})
