export default ($) => ({
  init(element) {
    element.find('.wa-header__search--button').on('click', function (e) {
      e.preventDefault()
      const dropdown = $(this).parent()
      if(!dropdown.hasClass('act')){
        dropdown.addClass('act')
        setTimeout( () =>{
          dropdown.find('input').focus()
        },300)
        
      } else {
        dropdown.removeClass('act')
      }
    })
    //click outside
    $(document).bind('click', function(e) {
      var $clicked = $(e.target);
      if ((!$clicked.parents().hasClass("wa-header__search") || $clicked.hasClass("wa-header__search--button")) && !$clicked.parents().hasClass("wa-header-center__search")) {          
        $('.wa-header-center__search').removeClass('act');                
        $('.wa-header__search').removeClass('act');                
      }
    });

  }
})