import simpleParallax from 'simple-parallax-js';

export default () => ({
  init() {
    try {
      const parallaxElements = document.querySelectorAll('.parallax-scroll');
      parallaxElements.forEach(element => {
        new simpleParallax(element, {
          delay: parseFloat(element.getAttribute("data-delay")) || 0,
          transition: 'cubic-bezier(0,0,0,0.2)',
          scale: parseFloat(element.getAttribute("data-scale")) || 1.2,
          overflow: true
        });

      });
    } catch (error) {
      console.error('Error', error)
    }
  }
})
