import * as $ from 'jquery';
import popup from './libs/popup'; // import popup
import parallaxAnimation from "./libs/parallaxAnimation";
import dropdownSearch from "./libs/dropdown-search";
import header from "./libs/header";
import burgermenu from './libs/burger-menu' //import the js file
import responsiveImage from './libs/responsive-images.js'; 

const responsive = responsiveImage($)

window.$ = $

// document.addEventListener("DOMContentLoaded", () => {

// })

$( document ).ready(function() {
  popup($).closeGeneral()
  parallaxAnimation($).init()
  header($).init()
  dropdownSearch($).init($(".wa-header__search"))
  burgermenu($).init()

  const elemBanner = $('.banner-content figure img');
	responsive.init(elemBanner, {
		type: 'image'
	})
});


// init Popup all pages
window.openPopup = (id) => {
  popup($).openPopup(id)
}
window.closePopup = (id) => {
  popup($).closePopup(id)
}


// init slider all pages
// window.slider = (element, options) => {
//   slider($).init(element, options)
// }
