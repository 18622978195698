export default ($) => ({
  init() {
    $('.burger-button').on('click', function (e) {
      e.preventDefault()
      const dropdown = $(this)
      const dropdownContent = $('.wa-menu-right')

      $('body').addClass('lock')
      dropdownContent.stop().fadeToggle(400)
      dropdownContent.css('right','0px')
    })
    $('.wa-menu-right__close').on('click', function (e) {
      e.preventDefault()
      const dropdown = $('.burger-button')
      const dropdownContent = $('.wa-menu-right')

      dropdown.removeClass('active')
      dropdownContent.stop().fadeOut(400)
      dropdownContent.css('right','-374px')
      $('body').removeClass('lock')
    })
    $('.wa-menu-right__content ul > li.have-sub > .arrow').on('click', function (e) {
      e.preventDefault()
      const dropdown = $(this).parent()
      const dropdownContent = $(this).next('ul')
      if(!dropdown.hasClass('active')){
        dropdown.parent().children('.have-sub').removeClass('active')
        dropdown.parent().find('> .have-sub > ul').slideUp()
        dropdown.addClass('active')
        dropdownContent.slideDown()
      }  else {
        dropdown.removeClass('active')
        dropdownContent.slideUp()
      }
      // dropdownContent.slideToggle(200)
      // dropdown.toggleClass('active')

      // const dropdownActive = $('.wa-menu-right__content ul > li.active > a').not($(this))
      // dropdownActive.next('ul').slideToggle(200)
      // dropdownActive.parent().removeClass('active')
    })

    $('.wa-header .wa-header__nav li.have-sub > .arrow, .wa-header-center__nav li.have-sub > .arrow').on('click', function (e) {

      const parents = $(this).parents('.have-sub')
      const dropdown = $(this).parent()
      const dropdownContent = $(this).next('ul')

      if($(window).width() > 1200){
        if(!dropdown.parent().hasClass('wa-header__nav') && !dropdown.parent().hasClass('wa-header-center__nav')){
          e.preventDefault()

          if(!dropdown.hasClass('active')){
            dropdownContent.slideDown(200)
            dropdown.addClass('active')
          } else {
            dropdownContent.slideUp(200)
            dropdown.removeClass('active')
          }
        }

      } else {
        e.preventDefault()
        if(!dropdown.hasClass('active')){
          if(!parents.hasClass('active')){
            $(this).parents('.wa-header__nav').find('> .have-sub > ul').slideUp(200)
            $(this).parents('.wa-header-center__nav').find('> .have-sub > ul').slideUp(200)
          }
          $(this).parents('.wa-header__nav').children('.have-sub').removeClass('active')
          $(this).parents('.wa-header-center__nav').children('.have-sub').removeClass('active')
          dropdownContent.slideDown(200)
          $(this).parents('.have-sub').addClass('active')
          dropdown.addClass('active')
        } else {

          dropdownContent.slideUp(200)
          dropdown.removeClass('active')
        }
      }

    })

    $('.wa-header .wa-header__nav li.have-sub-thumb > .arrow, .wa-header-center__nav li.have-sub-thumb > .arrow').on('click', function (e) {

      const dropdown = $(this).parent()
      const dropdownContent = $(this).next('.drop-sub-menu')

      if($(window).width() < 1279){
        e.preventDefault()
        if(!dropdown.hasClass('active')){
          $(this).parents('.wa-header__nav').find('.drop-sub-menu').hide()
          $(this).parents('.wa-header-center__nav').find('.drop-sub-menu').hide()
          $(this).parents('.wa-header__nav').children('.have-sub-thumb').removeClass('active')
          $(this).parents('.wa-header-center__nav').children('.have-sub-thumb').removeClass('active')
          dropdownContent.slideDown(200)
          dropdown.addClass('active')
        } else{
          dropdownContent.slideUp(200)
          dropdown.removeClass('active')
        }

      }

    })
  }
})
